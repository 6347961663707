@import "variable";

/*1. Use a more-intuitive box-sizing model.*/
*, *::before, *::after {
  box-sizing: border-box;
}

/*
  2. Remove default margin
*/
* {
  margin: 0;
}

/*
  Typographic tweaks!
  3. Add accessible line-height
  4. Improve text rendering
*/
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

/*
  5. Improve media defaults
*/
img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}

/*
  6. Remove built-in form typography styles
*/
input, button, textarea, select {
  font: inherit;
}

/*
  7. Avoid text overflows
*/
p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

a {
  text-decoration: none;
  color: inherit;
}

.text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.multiline-text-truncate {
  display: -webkit-box;
  max-width: 500px;
  width: 500px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.select-dropdown-menu {
  &.select-dropdown-menu-custom {
    .select-dropdown-styles__control {
      border-color: $gray4;
      //height: 60px;
      padding-left: 5px;
      font-size: 16px;
      box-shadow: none;

      &:hover,
      &:focus-visible,
      &:focus {
        border-color: $primary;
        outline: none !important;
        box-shadow: none !important;
      }
    }

    .select-dropdown-styles__multi-value {
      background-color: $gray4;
      border-radius: 10px;
      margin-right: 5px;
      padding: 5px;
      color: $primary;
      font-weight: 500;

      .select-dropdown-styles__multi-value__remove {
        cursor: pointer;
        border-radius: 10px;
      }
    }

    .select-dropdown-styles__placeholder {
      font-size: 16px;
    }

    .select-dropdown-styles__menu {
      padding: 10px;
      box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
    }

    .select-dropdown-styles__option {
      font-size: 16px;
      color: $primary;
    }
  }

  .select-dropdown-styles {
    background-color: transparent;
    border-radius: 8px;
    border-color: #e7ebee;
    font-weight: 700;
  }

  .select-dropdown-styles__control {
    border-color: rgba(5, 6, 15, 0.08);
    background-color: transparent;
    border-radius: 8px;
    //height: 50px;
  }

  .select-dropdown-styles__indicator-separator {
    display: none;
  }

  .select-dropdown-styles__placeholder {
    font-size: 14px;
    font-weight: 400;
    color: #3f5467;
    padding-left: 3px;
  }

  .select-dropdown-styles__indicator {
    color: #a0afb7;
  }

  .select-dropdown-styles__menu {
    min-width: 250px;
    padding: 20px;
    box-shadow: 0px 4px 90px 0px rgba(0, 0, 0, 0.07);
  }

  .select-dropdown-styles__option--is-focused {
    background-color: #f6f6f6;
  }

  .select-dropdown-styles__option--is-selected {
    background-color: #f6f6f6 !important;
  }

  .select-dropdown-styles__option {
    border-radius: 8px;
    color: #3f5467;
    background-color: transparent;

    &:hover {
      background-color: #f6f6f6;
      cursor: pointer;
    }
  }
}

.quill {
  .ql-toolbar {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    &.ql-snow {
      border: 1px solid $gray4;
    }
  }

  .ql-container {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 10px 8px;
    font-family: "Inter", "Arial", "sans-serif";
    font-size: 16px;
    font-weight: 400;
    color: #333;

    &.ql-snow {
      border: 1px solid $gray4;
    }

    .ql-editor {
      padding: 0 5px;

      p {
        color: $bodyColor;
        font-size: 16px;

        a {
          color: #3f5467 !important;
          text-decoration: underline;
        }
      }
    }
  }
}