.sidebarMenu {
  .actions {
    display: none;
  }
}

.categoryActions {
  :global(.MuiListItemButton-root) {
    &:hover {
      .categoryActionsItem {
        visibility: visible;
      }
    }
  }

  .categoryActionsItem {
    visibility: hidden;
  }
}

.assessmentsListWrapper {
  .listActions {
    svg {
      color: #3f5467;
    }
  }
}

.assessmentResultWrapper {
  label {
    display: block;

    span {
      &:last-child {
        color: #3F5467 !important;
      }
    }
  }
}

.correctAnswer {
  color: #008945 !important;
}

.wrongAnswer {
  color: #F20100 !important;
}

.previewBtn, .useTemplateBtn {
  min-width: 50px !important;
  height: 35px !important;
  padding: 10px !important;
  border-radius: 6px !important;

  &:hover {
    svg {
      fill: #fff;
    }
  }
}

.tagsPreview {
  margin-bottom: 40px !important;

  > div {
    margin-bottom: 8px !important;
  }
}

.templatesPreviewActionsButton {
}

.templatePreviewItem {
  position: relative;
  background-color: rgb(255, 248, 233);
  padding: 10px;
  height: 150px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  &:hover {
    .useTemplateBtnWrapper {
      display: block;
    }
  }
}

.useTemplateBtnWrapper {
  display: none;
  position: absolute;
}

.templateTitle {
  line-height: 24px;
}

.accordionItem {
  margin-bottom: 10px;
}

.transparentTab {
  margin-bottom: 10px !important;

  :global(.MuiTabs-flexContainer) {
    background-color: transparent !important;
    padding: 0 !important;

    :global(.MuiTab-root) {
      padding: 8px !important;
      border-radius: 0 !important;
    }

    :global(.Mui-selected) {
      border-bottom: 2px solid red;
    }
  }
}